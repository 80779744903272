/**
 * Constructor
 */

type Config = {
  A8Profile: string
  SP3Profile: string
}

const configs: Config = {
  A8Profile: process.env.NEXT_PUBLIC_A8_PROFILE_URL as string,
  SP3Profile: process.env.NEXT_PUBLIC_SP3_PROFILE_URI as string,
}

/**
 * Module exports
 */
export default configs
