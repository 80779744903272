import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { ancient8Api } from '@/services/base-axios/ancient8Api'
import { missionApi } from '@/services/base-axios/missionApi'
import { acceler8Api } from '@/services/base-axios/acceler8Api'
import { marketplaceApi } from '@/services/base-axios/marketplaceApi'
import { UserService } from '@/services/user/a8User'
import { removeAccessToken, setAccessToken } from '@/utils/accessToken'
import { AuthEntity } from '@/services/auth/authType'
import {
  challengeEmailOtpForLogin,
  challengeEmailOtpForSignup,
  validateEmail,
} from '@/services/auth/emailAuth'
import { SP3UserService } from '@/services/user/s3User'
import { S3UserUpdateDto, IS3User } from '@/types/user'

/**
 *  UI Store
 */

export type UserProfile = {
  _id: string
  accessedSites: any
  avatar: string
  isEmailVerified: boolean
  isEnabled: boolean
  lastLoginTime: Date
  roles: string[]
  username?: string
  email?: string
  walletAddress?: string
} & IS3User

interface UpdateProfile {
  removeWallet?: boolean
}
export interface UserStore {
  isStoreReady: boolean
  userProfile?: UserProfile
  authEntities: AuthEntity[]
  accessToken: string
  setIsStoreReady: (isMounted: boolean) => void
  login: (accessToken: string, walletAddress?: string) => Promise<UserProfile>
  logout: () => void
  updateProfile: ({ removeWallet }: UpdateProfile) => Promise<void>
  updateSP3Profile: (payload: S3UserUpdateDto) => void
  connectWallet: (walletAddress: string) => Promise<void>
  sendEmailOTP: (emailAddress: string) => Promise<void>
}

export const initialState = {
  accessToken: '',
  userProfile: undefined,
  authEntities: [],
}

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        isStoreReady: false,
        setIsStoreReady: (isStoreReady: boolean) => {
          set({ isStoreReady })
        },
        login: async (accessToken: string, walletAddress?: string) => {
          setAccessToken(accessToken)
          updateAxiosAuth(accessToken)
          const [user, authEntities, sp3User] = await Promise.all([
            UserService.fetchUserProfile(),
            UserService.fetchAuthEntities(),
            SP3UserService.getOne(),
          ])

          await UserService.updateMember()
          set({
            accessToken,
            userProfile: { ...sp3User, ...user, walletAddress },
            authEntities,
          })
          return { ...sp3User, ...user, walletAddress }
        },
        logout: async () => {
          removeAccessToken()
          updateAxiosAuth()
          set(initialState)
        },
        updateProfile: async ({ removeWallet }) => {
          const [user, authEntities] = await Promise.all([
            UserService.fetchUserProfile(),
            UserService.fetchAuthEntities(),
            SP3UserService.removeAuthCache(),
          ])

          set({
            userProfile: {
              cover: get().userProfile?.cover,
              ...user,
              walletAddress: removeWallet
                ? undefined
                : get().userProfile?.walletAddress,
            },
            authEntities,
          })
        },
        updateSP3Profile: async (payload: S3UserUpdateDto) => {
          const s3User = await SP3UserService.update(payload)
          const userProfile = get().userProfile
          if (userProfile !== undefined) {
            set({
              userProfile: {
                ...userProfile,
                cover: s3User.cover,
              },
            })
          }
        },
        connectWallet: async (walletAddress: string) => {
          const user = await UserService.fetchUserProfile()
          set({
            userProfile: {
              cover: get().userProfile?.cover,
              ...user,
              walletAddress,
            },
          })
        },
        async sendEmailOTP(emailAddress: string) {
          const existed = await validateEmail(emailAddress)
          const challenge = existed
            ? challengeEmailOtpForLogin
            : challengeEmailOtpForSignup
          await challenge(emailAddress)
        },
      }),
      {
        name: 'user',
        onRehydrateStorage: () => (state) => {
          if (state?.setIsStoreReady) {
            state.setIsStoreReady(true)
          }
        },
      },
    ),
  ),
)

const updateAxiosAuth = (accessToken?: string) => {
  const authorization = accessToken ? `Bearer ${accessToken}` : null

  const axiosInstances = [ancient8Api, missionApi, marketplaceApi, acceler8Api]

  axiosInstances.forEach(
    (instance) => (instance.defaults.headers['Authorization'] = authorization),
  )
}
