import { IConnector } from '@/components/login'
import { extraWalletsInfo } from '@/constants/loginModal'
import { useEffect, useMemo, useState } from 'react'
import { useAccount, useConnect } from 'wagmi'

type ConnectParams = Parameters<typeof useConnect>[0]

export default function useConnectors(params?: ConnectParams) {
  const { connect, connectors, error, status } = useConnect(params)
  const { address, isConnected, connector: currentConnector } = useAccount()
  const [walletAddress, setWalletAddress] = useState('')

  useEffect(() => {
    if (address) {
      if (currentConnector?.id === 'roninWallet') {
        const roninAddress = 'ronin:' + address.slice(2)
        setWalletAddress(roninAddress.toLowerCase())
      } else {
        setWalletAddress(address.toLowerCase())
      }
    }
  }, [address, currentConnector?.id])

  const wallets: IConnector[] = useMemo(() => {
    return connectors.map((c: IConnector) => {
      const extraInfo = extraWalletsInfo.find((item) => item.id === c.id)
      return Object.assign(c, extraInfo)
    })
  }, [connectors])

  return {
    currentConnector,
    connect,
    wallets,
    error,
    status,
    walletAddress,
    isConnected,
  }
}
