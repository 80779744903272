import api from './api.config'
import sol from './sol.config'
import profile from './profile.config'
import community from './community.config'
import location from './loction.config'
import social from './social.config'
import contracts from './space3-contract.config'
import blockscout from './blockscout.config'
import system from './system.config'

const configs = {
  sol,
  api,
  profile,
  community,
  location,
  social,
  contracts,
  blockscout,
  system,
}

export default configs
