/**
 * Constructor
 */

type Config = {
  discordClientId: string
  facebookClientId: string
  googleClientId: string
  twitterClientId: string
}

/**
 * Location config is used for SEO
 */
const configs: Config = {
  discordClientId: process.env.NEXT_PUBLIC_SP3_DISCORD_CLIENT_ID as string,
  facebookClientId: process.env.NEXT_PUBLIC_SP3_DISCORD_CLIENT_ID as string,
  googleClientId: process.env.NEXT_PUBLIC_SP3_GOOGLE_CLIENT_ID as string,
  twitterClientId: process.env.NEXT_PUBLIC_SP3_TWITTER_CLIENT_ID as string,
}

/**
 * Module exports
 */
export default configs
