import { PublicKey } from '@solana/web3.js'
import numbro from 'numbro'
import slugify from 'slugify'
import { isAddress } from 'ethers'

import { SPECIAL_CHARS_PATTERN } from '@/constants'
import dayjs from 'dayjs'

/**
 * Validate Solana address
 * @param address Solana address
 * @returns true/false
 */
export const isSolanaAddress = (
  address: string | undefined,
): address is string => {
  if (!address) return false
  try {
    const publicKey = new PublicKey(address)
    if (!publicKey) throw new Error('Invalid public key')
    return true
  } catch (er) {
    return false
  }
}

/**
 * Validate Ethereum address
 * @param address Ethereum address
 * @returns true/false
 */
export const isEthereumAddress = (
  address: string | undefined,
): address is string => {
  if (!address) return false
  return isAddress(address)
}

/**
 * Validate Ronin address
 * @param address Ronin address
 * @returns true/false
 */
export const isRoninAddress = (
  address: string | undefined,
): address is string => {
  if (!address || !address.startsWith('ronin:')) return false

  return isAddress(address.replace(/^ronin:/, '0x'))
}

export const shortenAddress = (
  address?: string,
  num = 4,
  delimiter = '...',
) => {
  if (!address) return undefined
  return (
    address.substring(0, num) +
    delimiter +
    address.substring(address.length - num, address.length)
  )
}

export const shortenAddress2Direction = (
  address: string,
  before = 4,
  after = 4,
  delimiter = '...',
) => {
  if (!address) return ''

  return (
    address.substring(0, before) +
    delimiter +
    address.substring(address.length - after, address.length)
  )
}

export const ellipsisText = (address: string, num = 4, delimiter = '...') => {
  if (address.length > num) return address.substring(0, num) + delimiter
  return address
}

export const numeric = (
  value?: number | string | bigint,
): ReturnType<typeof numbro> => {
  if (!value) return numbro('0')
  return numbro(value)
}

export const convertTwoDigits = (value: number | undefined) => {
  if (!value && value !== 0) return ''

  if (value < 10) return `0${value}`
  return value
}

/**
 * Validate Email address
 * @param email Email address
 * @returns true/false
 */
export const isEmailAddress = (email: string | undefined): email is string => {
  if (!email) return false
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email)
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1)
}

export const getNumber = (value: number, numOfDigits = 8): string => {
  return Number(value)
    .toFixed(Number(numOfDigits))
    .replace(/\.?0+$/, '')
}

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max)

export const removeURLQuery = (url: string | undefined) => {
  if (!url) return ''

  if (url.includes('?')) {
    const { origin, pathname } = new URL(url)
    return `${origin}${pathname}`
  }

  return url
}

export const thousandSeparator = (value: number | string | undefined) => {
  if (!value && value !== 0) return ''

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 *
 * Format Ordinal Number Suffixes
 */
export const getNumberWithOrdinal = (number: number) => {
  const j = number % 10
  const k = number % 100

  if (j === 1 && k !== 11) {
    return number + 'st'
  }

  if (j === 2 && k !== 12) {
    return number + 'nd'
  }

  if (j === 3 && k !== 13) {
    return number + 'rd'
  }

  return number + 'th'
}

export const generateReferralUsername = (title: string) => {
  return slugify(title, {
    replacement: '',
    lower: true,
    locale: 'en',
    remove: SPECIAL_CHARS_PATTERN,
    trim: true,
  })
}

// This function shortens the decimal part of a number.
export const shortenDecimal = (amount: number, maxDecimal = 8): string => {
  return numbro(amount).format({
    mantissa: maxDecimal,
    trimMantissa: true,
    roundingFunction: (num) => Math.floor(num),
  })
}

/**
 * Checks if the given date is within the specified limit of days from the current date.
 * @param {string} dateString The date string to check.
 * @param {number} limit The limit of days.
 * @returns {boolean} True if the date is within the limit, otherwise false.
 */
export const isDateWithinLimit = (
  dateString: string,
  limit: number,
): boolean => {
  if (!dateString) {
    return false
  }

  const currentDate = dayjs()
  const endDate = dayjs(dateString)
  const differenceInDays = endDate.diff(currentDate, 'days')

  return differenceInDays <= limit
}

export const millionAverage = (value?: number) => {
  if (!value) return '0'
  if (value >= 1_000_000) return `${Math.floor(value / 1_000_000)}M+`
  return `${value}`
}
