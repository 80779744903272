import { S3UserData, S3UserUpdateDto, UpdateUserSocial } from '@/types/user'
import { missionApi as api } from '../base-axios/missionApi'

const CONTROLLER = '/user'

class SP3User {
  async getOne(): Promise<S3UserData> {
    const { data } = await api.get<S3UserData>(`${CONTROLLER}`)
    return data
  }
  async update(payload: S3UserUpdateDto) {
    const { data } = await api.put<S3UserData>(`${CONTROLLER}`, payload)
    return data
  }
  async removeAuthCache() {
    await api.delete(`${CONTROLLER}/auth-cache`)
  }

  async updateUserSocialInfo({ type, info }: UpdateUserSocial) {
    const { data } = await api.patch(`${CONTROLLER}/social`, {
      type,
      info,
    })
    return data
  }
}

export const SP3UserService = new SP3User()
