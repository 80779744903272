import { Connection } from '@solana/web3.js'
/**
 * Constructor
 */

type Config = {
  connection: Connection
  adminAddress: string
}

const configs: Config = {
  connection: new Connection(process.env.NEXT_PUBLIC_SOLANA_ENDPOINT as string),
  adminAddress: process.env.NEXT_PUBLIC_SOLANA_ADMIN_ADDRESS as string,
}

/**
 * Module exports
 */
export default configs
